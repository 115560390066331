<template>
	<div class="data-content">
		<div class="top-box">
			<div>
				<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('train:company:add')">新增</button>
				<button class="import-btn" @click="handleImport" v-if="perms.includes('train:company:import')">导入</button>
				<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
				<button class="import-btn" @click="handleExport" v-if="perms.includes('train:company:export')">导出</button>
				<button class="template-btn" @click="handleTemplate" v-if="perms.includes('train:company:import')">模板下载</button>
			</div>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">区域</label>
				<el-select class="search-input" size="small" v-model="area" filterable placeholder="请选择" @change="handleArea">
					<el-option value="" label="全部"></el-option>
					<el-option
						v-for="item in areaList"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<label class="label">省市</label>
				<el-select class="search-input" size="small" v-model="provice" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option
						v-for="item in proviceList"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<label class="label">有效性</label>
				<el-select class="search-input" size="small" v-model="active" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option value="1" label="有效"></el-option>
					<el-option value="0" label="失效"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dealerList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
						width="50"
						label="NO.">
						<template slot-scope="scope">
							<span class="no">{{scope.$index+1}}</span>
						</template>
					</el-table-column>
				<el-table-column
					prop="code"
					width="90"
					show-overflow-tooltip
					label="公司编码">
				</el-table-column>
				<!-- <el-table-column
					prop="companyCode"
					label="公司代码">
				</el-table-column> -->
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="公司名称">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					width="80"
					show-overflow-tooltip
					label="区域">
				</el-table-column>
				<el-table-column
					prop="areaName"
					width="80"
					show-overflow-tooltip
					label="省市">
				</el-table-column>
				<el-table-column
					prop="contractLevelName"
					show-overflow-tooltip
					width="100"
					label="签约级别">
				</el-table-column>
				<el-table-column
					prop="parentNames"
					show-overflow-tooltip
					width="150"
					label="所属经销商">
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="50">
					<template slot-scope="scope">
						<span v-if="scope.row.active == 1">Y</span>
						<span v-if="scope.row.active == 0" style="color:red;">N</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:company:update') && scope.row.contractLevelName == '网络店'">修改</button>
						<button class="btn-blue" @click="changeStatus(scope.row)" v-if="perms.includes('train:company:active') && scope.row.active == 0 && scope.row.contractLevelName == '网络店'">激活</button>
						<button class="btn-red" @click="changeStatus(scope.row)" v-if="perms.includes('train:company:invalid') && scope.row.active == 1 && scope.row.contractLevelName == '网络店'">失效</button>
						<!-- <button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:company:delete') && scope.row.contractLevelName == '网络店'">删除</button> -->
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getDealerList="getDealerList"></edit-data>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			active: '',
			area: '',
			areaList: [],
			provice: '',
			proviceList: [],
			dealerList: [],
			dataList: [],
			deviceType: '',
			deviceTypeList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		// 获取区域数据
		this.getAreaList();
		this.pageNo = 1;
		this.searchKey = '';
		this.getDealerList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 获取区域数据
		getAreaList() {
			let that = this;
			that.$request.post(
				"sysDataDetailPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					code: 'areaType'
				},
				function (r) {
					if (r.code == "0") {
						that.areaList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取省市数据
		handleArea() {
			this.provice = '';
			if(this.area == '') {
				this.proviceList = [];
				return
			}

			let that = this;
			that.$request.post(
				"sysAreaPageList",
				false,
				{
					pageNo: 1,
					pageSize: 999,
					dataId: that.area
				},
				function (r) {
					if (r.code == "0") {
						that.proviceList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.rangeId = '';
			this.areaId = '';
			this.pageNo = 1;
			this.getDealerList();
		},
		// 获取公司数据
		getDealerList() {
			let that = this;
			that.$request.post(
				"pageMapCompanyList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					rangeId: that.area,
					active: that.active,
					areaId: that.provice
				},
				function (r) {
					if (r.code == "0") {
						that.dealerList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 新增/编辑/查看
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		// 更改公司状态
		changeStatus(dt) {
			let tips = dt.active == 1 ? '是否失效当前公司?' : '是否激活当前公司?';
			let statusUrl = dt.active == 1 ? 'invalidCompany' : 'activeCompany';
			this.$confirm(tips, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					statusUrl,
					true,
					{
						id: dt.id
					},
					function (r) {
						if (r.code == "0") {
							that.getDealerList();
							that.$message.success('公司状态已变更');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前公司?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysAgentDelete',
					true,
					{
						id: data.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getDealerList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDealerList();
		},
		handleSizeChange(val) {
			this.getDealerList();
		},
		handleCurrentChange(val) {
			this.getDealerList();
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'sysCompanyImport',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getDealerList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"sysCompanyExport",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					rangeId: that.area,
					active: that.active,
					areaId: that.provice
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/COMPANY-DATA.xlsx';
			linkNode.download = 'COMPANY-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		// height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		// float: right;
		text-align: right;
		margin-top: 15px;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>

